import React, { useState } from 'react'
import { PageRendererProps, navigate } from 'gatsby'

import { useQuery, useMutation, useQueryClient } from 'react-query'
import Layout from '../../templates/LiveLayout'
import UploadWorkoutDetails, {
  WorkoutField,
} from '../../components/UploadWorkout/UploadWorkoutDetails'
import UploadVideo from '../../components/UploadWorkout/UploadVideo'
import { api, apiWithFormData, Methods } from '../../services/httpService'
import { ITrainerResponse } from '../../services/fetch'
import Snack, { initialSnackState, SnackState } from '../../components/Snackbar'
import { TRAINER_RECORDINGS } from '../../constants/routes'

export interface Config {
  title?: WorkoutField
  description?: WorkoutField
  descriptionTwo?: WorkoutField
  tags?: WorkoutField
  length?: WorkoutField
  thumbnail?: WorkoutField
  videoUrl?: WorkoutField
  spotifyLink?: WorkoutField
  intensity?: WorkoutField
}

export interface CreateWorkoutRequest {
  title: string
  description: string
  tags: string[]
  format: string
  length: number
  thumbnail: string
  trainerId?: number
  spotifyLink: string
  descriptionTwo: string
  intensity: string
  videoUrl?: string
}

const UploadWorkout = (props: PageRendererProps) => {
  const [workoutId, setWorkoutId] = useState<number>(undefined)
  const [videoUploadFinished, setVideoUploadFinished] = useState<boolean>(false)
  const [{ open, isSuccess, message }, setSnackState] = useState<SnackState>(
    initialSnackState,
  )
  const [skippedToLink, setSkipToLink] = useState(false)
  const queryClient = useQueryClient()

  const workoutConfig = useQuery(
    'config:workout',
    async () => await api<WorkoutField[]>(Methods.get, '/config/workout'),
    {
      select: data => {
        const config: Config = {}
        data.map(item => {
          config[item.field] = item
        })
        return config
      },
    },
  )

  const trainers = useQuery(
    'trainers',
    async () => await api<ITrainerResponse[]>(Methods.get, '/trainer'),
  )

  const initializeWorkout = useMutation(
    async () => await api<object & { id: number }>(Methods.post, '/chunk/init'),
    {
      onSuccess: data => setWorkoutId(data.id),
    },
  )

  const createWorkout = useMutation(
    async (request: CreateWorkoutRequest) =>
      await api(Methods.patch, `/chunk/finish/${workoutId}`, request),
    {
      onError: (error: any) => {
        setSnackState({
          open: true,
          isSuccess: false,
          message:
            error?.message ||
            "We're sorry, it looks like there was an error. Please try again, or contact your Solin account manager if issues persist.",
        })
      },
      onSuccess: () => {
        setSnackState({
          open: true,
          isSuccess: true,
          message: 'Congratulations, your workout has been saved!',
        })
        navigate(TRAINER_RECORDINGS)
        queryClient.invalidateQueries('workouts')
      },
    },
  )

  const createWorkoutWithVideoUrl = useMutation(
    async (request: CreateWorkoutRequest) =>
      await api(Methods.post, '/workout', request),
    {
      onError: (error: any) => {
        setSnackState({
          open: true,
          isSuccess: false,
          message:
            error?.message ||
            "We're sorry, it looks like there was an error. Please try again, or contact your Solin account manager if issues persist.",
        })
      },
      onSuccess: () => {
        setSnackState({
          open: true,
          isSuccess: true,
          message: 'Congratulations, your workout has been saved!',
        })
        navigate(TRAINER_RECORDINGS)
        queryClient.invalidateQueries('workouts')
      },
    },
  )

  const imageUpload = useMutation(
    async (file: File) => {
      const formData = new FormData()
      formData.append('image', file)
      return await apiWithFormData<{ imageURL: string }>(
        '/upload/image',
        formData,
      )
    },
    {
      onError: (error: any) => {
        setSnackState({
          open: true,
          isSuccess: false,
          message:
            error?.message ||
            "We're sorry, it looks like there was an error. Please try again, or contact your Solin account manager if issues persist.",
        })
      },
    },
  )

  const handleUploadFinished = (value: boolean) => setVideoUploadFinished(value)

  return (
    <Layout title="Upload Workout" location={props.location}>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <UploadVideo
          initialize={initializeWorkout}
          uploadFinished={videoUploadFinished}
          setUploadFinished={handleUploadFinished}
          handleSkipToLink={() => setSkipToLink(true)}
          skippedToLink={skippedToLink}
        />
        {skippedToLink && (
          <UploadWorkoutDetails
            config={workoutConfig.data}
            trainers={trainers.data}
            workoutId={null}
            skippedToLink={skippedToLink}
            isVideoUploaded={videoUploadFinished}
            createWorkout={(request: CreateWorkoutRequest) =>
              createWorkoutWithVideoUrl.mutateAsync(request) as Promise<void>
            }
            imageUpload={(file: File) =>
              imageUpload.mutateAsync(file) as Promise<{ imageURL: string }>
            }
          />
        )}
        {!skippedToLink && !workoutConfig.isLoading && !trainers.isLoading && (
          <UploadWorkoutDetails
            config={workoutConfig.data}
            trainers={trainers.data}
            workoutId={workoutId}
            skippedToLink={skippedToLink}
            isVideoUploaded={videoUploadFinished}
            createWorkout={(request: CreateWorkoutRequest) =>
              createWorkout.mutateAsync(request) as Promise<void>
            }
            imageUpload={(file: File) =>
              imageUpload.mutateAsync(file) as Promise<{ imageURL: string }>
            }
          />
        )}
      </div>
      <Snack
        open={open}
        isSuccess={isSuccess}
        disableAutoHide={true}
        message={message}
        close={() => {
          setSnackState(initialSnackState)
        }}
      />
    </Layout>
  )
}

export default UploadWorkout
