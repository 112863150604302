import React, { useState } from 'react'
import { BtnSelect } from '../../components/forms/util/BtnSelect'
import Select from '@material-ui/core/Select'
import Slider from '@material-ui/core/Slider'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import InputLabel from '@material-ui/core/InputLabel'
import * as Colors from '../../constants/colors'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import ImageCropper from '../../components/live/imageCropper'
import * as S from '../../styles/login-live.styles'
import { ITrainer } from '../../types'
import { CircularProgress } from '@material-ui/core'
import { Config, CreateWorkoutRequest } from '../../pages/live/upload-video'
import { ITrainerResponse } from '../../services/fetch'

const DEFAULT_WORKOUT_FORMAT = 'single-wide'

export interface WorkoutField {
  label: string
  field: string
  type: string
  values?: string[]
}

export interface WorkoutState {
  thumbnailFile: File
  title: string
  description: string
  descriptionTwo?: string
  length: number
  spotifyLink?: string
  tags: string[]
  intensity?: string
  videoUrl?: string
}

const defaultWorkoutState = {
  thumbnailFile: null,
  title: '',
  description: '',
  descriptionTwo: '',
  spotifyLink: '',
  length: 60,
  tags: [],
  intensity: '',
  videoUrl: '',
}

interface Props {
  config: Config
  trainers: ITrainerResponse[]
  workoutId: number
  isVideoUploaded: boolean
  skippedToLink: boolean
  createWorkout: (request: CreateWorkoutRequest) => Promise<void>
  imageUpload: (file: File) => Promise<{ imageURL: string }>
}

const UploadWorkoutDetails = (props: Props) => {
  const {
    workoutId,
    isVideoUploaded,
    config,
    trainers,
    createWorkout,
    imageUpload,
  } = props
  const [workoutState, setWorkoutState] = useState<WorkoutState>(
    defaultWorkoutState,
  )
  const [imagePreview, setImagePreview] = useState(undefined)
  const [selectedTrainer, setSelectedTrainer] = useState<ITrainer | undefined>(
    undefined,
  )
  const [isUploadingWorkout, setIsUploadingWorkout] = useState<boolean>(false)

  /**
   * If workoutId is null, tell trainer to select video to be uploaded first
   */
  if (!workoutId && !props.skippedToLink) {
    return null
  }

  const {
    thumbnailFile,
    title,
    description,
    length,
    tags,
    descriptionTwo,
    spotifyLink,
    intensity,
    videoUrl,
  } = workoutState

  const handleTrainerSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const trainer = trainers.find(val => val.id === Number(e.target.value))
    setSelectedTrainer(trainer)
  }

  const handleUploadClickThumbnail = (file: File) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onloadend = () => {
      // @ts-ignore
      setImagePreview(reader.result)
    }
    setWorkoutState({
      ...workoutState,
      thumbnailFile: file,
    })
  }

  const handleWorkoutUpload = async (
    e: React.FormEvent<HTMLFormElement>,
  ): Promise<void> => {
    e.preventDefault()
    setIsUploadingWorkout(true)
    const { imageURL } = await imageUpload(thumbnailFile)

    const createWorkoutRequest: CreateWorkoutRequest = {
      title,
      description,
      tags,
      format: DEFAULT_WORKOUT_FORMAT,
      length,
      thumbnail: imageURL,
      intensity,
      // intentionally passing trainer's `trainer` id rather then `user` id
      trainerId: selectedTrainer.id,
      ...{
        spotifyLink: config?.spotifyLink ? spotifyLink : undefined,
      },
      ...{
        descriptionTwo: config?.descriptionTwo ? descriptionTwo : undefined,
      },
      ...{
        videoUrl:
          config?.videoUrl && props.skippedToLink ? videoUrl : undefined,
      },
    }

    await createWorkout(createWorkoutRequest)
    setIsUploadingWorkout(false)
  }

  return (
    <>
      <S.Container style={{ marginTop: 16, marginBottom: 16 }}>
        <form onSubmit={handleWorkoutUpload}>
          <S.WrapCol>
            <FormControl variant="outlined">
              <InputLabel id="demo-simple-select-outlined-label">
                Trainer
              </InputLabel>
              <Select
                labelId="outlined-label-trainer"
                id="select-outlined-tainer"
                value={selectedTrainer?.id || ''}
                // @ts-ignore
                onChange={e => handleTrainerSelect(e)}
                label="Trainer"
                required={true}
              >
                {trainers.map((item, index) => (
                  <MenuItem key={index} value={item.id}>
                    <S.TrainerRow>
                      <S.TrainerImage src={item.profilePictureURL} />
                      {item.firstName} {item.lastName}
                    </S.TrainerRow>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <S.Spacer />

            {config?.title && (
              <>
                <TextField
                  label="Title"
                  type="text"
                  value={title}
                  onChange={e =>
                    setWorkoutState({
                      ...workoutState,
                      title: e.target.value,
                    })
                  }
                  variant="outlined"
                  style={{ width: '100%' }}
                  required
                />
                <S.Spacer />
              </>
            )}

            {config?.description && (
              <>
                <TextField
                  value={description}
                  label="Description"
                  placeholder="Tell consumers a little more about your class and list any equipment needed"
                  onChange={e =>
                    setWorkoutState({
                      ...workoutState,
                      description: e.target.value,
                    })
                  }
                  multiline={true}
                  variant="outlined"
                  style={{ width: '100%' }}
                  required
                />
                <S.Spacer />
              </>
            )}

            {config?.descriptionTwo && (
              <>
                <TextField
                  value={descriptionTwo}
                  label="Description Two (optional)"
                  placeholder="Tell consumers a little more about your class and list any equipment needed"
                  onChange={e =>
                    setWorkoutState({
                      ...workoutState,
                      descriptionTwo: e.target.value,
                    })
                  }
                  multiline={true}
                  variant="outlined"
                  style={{ width: '100%' }}
                  required
                />
                <S.Spacer />
              </>
            )}

            {config?.spotifyLink && (
              <>
                <TextField
                  value={spotifyLink}
                  label="Music Playlist Link"
                  onChange={e =>
                    setWorkoutState({
                      ...workoutState,
                      spotifyLink: e.target.value,
                    })
                  }
                  multiline={true}
                  variant="outlined"
                  style={{ width: '100%' }}
                  required
                />
                <S.Spacer />
              </>
            )}

            {config?.videoUrl && props.skippedToLink && (
              <>
                <TextField
                  value={videoUrl}
                  label="Video Url"
                  onChange={e =>
                    setWorkoutState({
                      ...workoutState,
                      videoUrl: e.target.value,
                    })
                  }
                  variant="outlined"
                  style={{ width: '100%' }}
                  required
                />
                <S.Spacer />
              </>
            )}

            {config?.length && (
              <>
                <S.Spacer />
                <Typography
                  id="discrete-slider"
                  gutterBottom
                  style={{
                    color: Colors.themeColors.textColor,
                    fontSize: 16,
                  }}
                >
                  Duration (in minutes)
                </Typography>
                <Slider
                  value={length}
                  aria-labelledby="discrete-slider"
                  valueLabelDisplay="auto"
                  step={1}
                  marks
                  min={1}
                  max={120}
                  onChange={(e, value) =>
                    setWorkoutState({
                      ...workoutState,
                      length: value as number,
                    })
                  }
                />
              </>
            )}

            {config?.tags && (
              <>
                <S.Spacer />
                <BtnSelect
                  options={config?.tags.values.map(item => ({
                    key: item,
                    value: item,
                  }))}
                  values={tags || []}
                  label="Type (select all that apply)"
                  onClick={(values): void =>
                    setWorkoutState({
                      ...workoutState,
                      tags: values,
                    })
                  }
                />
              </>
            )}

            {config?.intensity && (
              <>
                <S.Spacer />
                <BtnSelect
                  options={config?.intensity.values.map(item => ({
                    key: item,
                    value: item,
                  }))}
                  values={[intensity]}
                  label="Intensity"
                  isSingleSelect={true}
                  onClick={(values): void =>
                    setWorkoutState({
                      ...workoutState,
                      intensity: values.shift(),
                    })
                  }
                />
              </>
            )}

            {config?.thumbnail && (
              <>
                {imagePreview && (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'flex-start',
                    }}
                  >
                    <S.ImagePreview src={imagePreview} />
                    <div style={{ paddingRight: 20 }} />
                    <IconButton
                      color="primary"
                      aria-label="remove video"
                      onClick={() => {
                        setWorkoutState({
                          ...workoutState,
                          thumbnailFile: null,
                        })
                        setImagePreview(null)
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </div>
                )}
                <ImageCropper onSave={handleUploadClickThumbnail} />
                <div style={{ marginBottom: 40 }} />
              </>
            )}

            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={!isVideoUploaded && !props.skippedToLink}
            >
              {isUploadingWorkout ? <CircularProgress size="2rem" /> : 'Save'}
            </Button>
          </S.WrapCol>
        </form>
      </S.Container>
    </>
  )
}

export default UploadWorkoutDetails
